import React from "react"
import { useEffect } from "react"
import { getCalApi } from "@calcom/embed-react"
import Cal from "@calcom/embed-react";

export default function Calendar({ name, calUrl }) {
  useEffect(() => {
    ;(async function () {
      const cal = await getCalApi()
      cal("ui", {
        theme: "dark",
        styles: {
          branding: { brandColor: "#050B15" },
        },
      })
    })()
  }, [])
  return (
    <a href={calUrl} rel="nofollow" target="_blank"
      className="order-1 px-6 py-4 text-base tracking-widest text-white no-underline uppercase border hover:text-white font-oswald border-primary hover:bg-primary"
      >
      Objednat se k {name}
    </a>
  )
}
