import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Services = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/prices.md/" }) {
        frontmatter {
          full_cut {
            full_cut_content
            full_cut_price
          }
          kid_cut {
            kid_cut_content
            kid_cut_price
          }
          men_beard {
            men_beard_content
            men_beard_price
          }
          men_beard_2 {
            men_beard_2_content
            men_beard_2_price
          }
          men_beard_color {
            men_beard_color_content
            men_beard_color_price
          }
          men_contour_color {
            men_contour_color_content
            men_contour_color_price
          }
          men_cut {
            men_cut_content
            men_cut_price
          }
        }
      }
    }
  `)

  const service = data.markdownRemark.frontmatter

  return (
    <section className="mx-auto md:pb-8" id="nase-sluzby">
      <div className="container">
        <h2 className="pb-8 text-center ">Naše služby</h2>
        <p className="mx-auto text-center md:max-w-lg ">
          Zaměřujeme se na perfektní střih a úpravu vousů. U nás odejdete
          nejenom se zážitkem, ale i s perfektním účesem.
        </p>
        <div className="grid gap-8 mx-auto mt-8 md:grid-cols-2">
          <div className="inline-flex flex-col p-8 space-y-10 border-2 bg-dark border-light">
            <p className="text-3xl leading-9 text-center text-white uppercase opacity-100 font-oswald">
              Střihy
            </p>
            <div>
              <div className="inline-flex justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  Pánský střih
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  {service.men_cut.men_cut_price} Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                45 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat">
                {service.men_cut.men_cut_content}
              </p>
            </div>

            <div>
              <div className="inline-flex justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  dětský střih (do 4 let)
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  {service.kid_cut.kid_cut_price} Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                30 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat">
                {service.kid_cut.kid_cut_content}
              </p>
            </div>
            <div>
              <div className="inline-flex justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  Střih dlouhých vlasů
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  660 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                60 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat">
                Konzultace, střih strojkem jedna velikost, zaholení kontur,
                úprava obočí, opalování uší, mytí vlasů, masáž hlavy, vysušení,
                styling a na závěr kolínská.
              </p>
            </div>
          </div>

          <div className="inline-flex flex-col p-8 space-y-10 border-2 bg-dark border-light ">
            <p className="text-3xl leading-9 text-center text-white uppercase opacity-100 font-oswald">
              vousy
            </p>
            <div>
              <div className="inline-flex items-center justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  Klasické holení břitvou "HOT TOWEL"
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  460 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                45 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat">
                Konzultace, napaření vousů horkým ručníkem, holení břitvou,
                zábal obličeje horkým ručníkem a na závěr kolínská.
              </p>
            </div>
            <div>
              <div className="inline-flex items-center justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  Úprava vousů “pouze strojkem“
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  300 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                15 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat">
                Úprava vousů strojkem, úprava obočí a opalování uší a na závěr
                kolínská.
              </p>
            </div>
            <div>
              <div className="inline-flex items-center justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  BARVENÍ VOUSŮ
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  280 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                Čas dle typu vousů
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat"></p>
            </div>
            <div>
              <div className="inline-flex items-center justify-between w-full ">
                <p className="mr-2 text-2xl leading-normal uppercase opacity-100 font-oswald text-light">
                  Depilace nosních a ušních chloupků
                </p>
                <p className="text-xl font-normal leading-normal uppercase whitespace-nowrap font-oswald text-light">
                  90 Kč
                </p>
              </div>
              <p className="mb-2 text-base uppercase font-oswald opacity-80">
                10 minut
              </p>
              <p className="text-sm normal-case opacity-80 font-montserrat"></p>
            </div>
          </div>
        </div>
        <div className="inline-flex flex-col p-8 mt-8 space-y-10 border-2 bg-dark border-primary ">
          <p className="text-3xl leading-9 text-center text-transparent uppercase opacity-100 font-oswald bg-clip-text bg-gradient-to-r from-primary to-secondary">
            All in one
          </p>
          <div>
            <div className="inline-flex items-center justify-between w-full ">
              <p className="mr-2 text-2xl leading-normal text-transparent uppercase opacity-100 font-oswald bg-clip-text bg-gradient-to-r from-primary to-secondary">
                Kompletka
              </p>
              <p className="text-xl leading-normal text-transparent uppercase opacity-100 whitespace-nowrap font-oswald bg-clip-text bg-gradient-to-r from-primary to-secondary">
                {service.full_cut.full_cut_price} Kč
              </p>
            </div>
            <p className="mb-2 text-base uppercase font-oswald opacity-80">
              (střih vlasů+střih vousů s HOT TOWEL)
            </p>
            <p className="mb-2 text-base uppercase font-oswald opacity-80">
              60 minut
            </p>
            <p className="text-sm normal-case opacity-80 font-montserrat">
              {service.full_cut.full_cut_content}
            </p>
          </div>
          <div>
            <div className="inline-flex items-center justify-between w-full ">
              <p className="mr-2 text-2xl leading-normal text-transparent uppercase opacity-100 font-oswald bg-clip-text bg-gradient-to-r from-primary to-secondary">
                Kompletka + depilace
              </p>
              <p className="text-xl leading-normal text-transparent uppercase opacity-100 whitespace-nowrap font-oswald bg-clip-text bg-gradient-to-r from-primary to-secondary">
                850 Kč
              </p>
            </div>
            <p className="mb-2 text-base uppercase font-oswald opacity-80">
              (STŘIH VLASŮ + STŘIH VOUSŮ S HOT TOWEL + DEPILACE)
            </p>
            <p className="mb-2 text-base uppercase font-oswald opacity-80">
              75 minut
            </p>
            <p className="text-sm normal-case opacity-80 font-montserrat">
              Konzultace, střih, zaholení kontur, úprava obočí, úprava vousů,
              napaření horkým ručníkem, holení břitvou, zábal obličeje horkým
              ručníkem, opalování uší, depilace nosních a ušních chloupků, mytí
              vlasů, vysušení, styling a na závěr kolínská.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
